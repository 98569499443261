<template>

	<div class="user">
		<h4>我的收藏/关注</h4>

		<div class="tab">
			<!-- <div :class="{'active':sign==1}" @click="change(1)">游记收藏</div> -->
			<div :class="{'active':sign==2}" @click="change(2)">产品收藏</div>
			<div class="bottom"></div>
		</div>

		<div class="coulist"  v-if="sign==1">
			<div class="couitem"   v-for="(item,i) in list" :key="i">
				<div class="c1">
					<p class="t1">{{item.title}}</p>
					<p class="t2">目的地：</p>
					<p class="t3">
						<span>{{item.create_time}}</span>
						<span @click="del(item.tid,2)">取消收藏</span>
					</p>
				</div>
				<div class="c2">
					<img :src="item.attachment">					
				</div>
			</div>
			
		</div>
		 <div class="couitem" v-if="sign==2">
				<table border="1" cellpadding="0" cellspacing="0" >
                    <tr>
                        <th>类型</th>
                        <th>产品名称</th>
                        <th>操作</th>
                    </tr>
                    <tr v-for="(item,i) in list" :key="i">
                        <td>{{item.pid | textType}}</td>
                        <td>{{item.goods_name}}</td>
                        <td @click="del(item.tid,1)" width="100">取消收藏</td>
                    </tr>
                   <tr>
					  <td colspan="3" v-if="list.length==0"> 暂无收藏</td>
				   </tr>
                </table>
			</div>
	</div>

</template>

<script>
import {collectionListAPI,collectionGoods} from "@/api/user"

import {collectionTakeDelAPI} from "@/api/trivialShot"
export default {

  data () {
    return {
      sign: 2,
	  list:[]

    }
  },
  created () {
	//   this.getlist()
	collectionGoods().then(res=>{
			this.list=res.cateList
			})
  },
  filters:{
	//   1=城市微游；2=境外旅行；3=国内长途；4=周边活动；5=趣·户外；6=趣·旅游
	textType(str){
		var arr=str.split(",");
		console.log(arr)
		var type=["","城市微游","境外旅行","国内长途","周边活动","趣·户外","趣·旅游","趣·跟团"]
		var text=[]
		for (const key in arr) {
			text.push(type[arr[key]])
		}
		return text.join(",")
	}
  },
  methods: {
    tourl () {
      this.$router.push({ path: '/user/orderInfo' })
    },
	getlist(){
		collectionListAPI().then(res=>{
			this.list=res.cateList
		})

	},

	change(n){
		this.sign=n
		if (n==1) {
			this.getlist()
		} else {
			collectionGoods().then(res=>{
			this.list=res.cateList
			})
		}
	},
	del(id,n){

		 
		  var data={
			  tid:id,
			  status:2,
			  type:n
		  }
		  collectionTakeDelAPI(data).then(res=>{
			 this.change(this.sign)
			  this.$message.success("取消收藏成功")
		  })
		}
  }
}
</script>

<style lang="scss" scoped="scoped">

	.user{
		padding: 5px 20px 20px;
		background: #ffffff;
		min-height: 500px;
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			text-indent: 10px;
		}

		.tab{
			display: flex;
			position: relative;
			div{
				cursor: pointer;
				width: 100px;
				height: 45px;
				font-size: 16px;
				font-weight: 500;
				line-height: 45px;
				text-align: center;
				color: #666666;
				border-bottom: 2px solid #cccccc;
				position: relative;
				z-index: 2;
			}
			.active{
				border-bottom: 2px solid #FF7C00;
				color: #ff7c00;
			}
			.bottom{
				width: 100%;
				height: 2px;
				border-bottom: 2px solid #cccccc;
				position: absolute;
				top: 43px;
				left: 0;
				z-index: 0;
			}

		}
		table{
			width: 100%;
			border-color:#fcfcfc; 
			margin-top: 20px;
			th{
				background: #eaeaea;
				line-height: 30px;
				// width: 33.33%;
				font-size: 13px;
			}
			td{
				text-align: center;
				line-height: 40px;
				font-size: 13px;
			}
			td:nth-child(3){
				color: #FF7C00;
				
				cursor: pointer;
			}
		}
		.coulist{
          display: flex;
			flex-wrap: wrap;
			.couitem{
				width: 460px;
				height: 130px;
				background: #f9f9f9;
				border-radius: 1px;
				padding: 10px;
				margin-top: 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.c1{
					flex: 1;

					margin-right: 10px;
					.t1{
						font-size: 16px;
						font-weight: 600;
						color: #333333;
						line-height: 26px;
					}

					.t3{
						font-size: 14px;
						font-weight: 400;
						color: #999999;
						line-height: 30px;
						display: flex;
						justify-content: space-between;

						span:nth-child(2){
							cursor: pointer;
							color: #FF7C00;
							font-size: 11px;
							vertical-align: middle;
							img{
								vertical-align: middle;
								margin-right: 4px;
							}
						}
					}
				}
				.c2{
					width: 210px;
					height: 100px;
					overflow: hidden;
					position: relative;
					border-radius: 4px;
					>img{
						width: 210px;
						min-height: 100px;
						border-radius: 4px;
					}
					

				}
			}
			.couitem:nth-child(odd){
				margin-right: 30px;
			}

		
			

		}
	}

</style>
